.steps-container {
    max-width: 90%;
}


.timeline-chapter-indicator {
    max-width: 50px;
}

.timeline-chapter {
    margin: 100px 0;
}


@media screen and (max-width: 767px) {
    .timeline-chapter-image {
        background-color: transparent;
    }

    .timeline-chapter {
        margin: 20px 0;
    }
}