#pricing #credits-explanation {
    max-width: 750px;
    margin: 0 auto;
}

#pricing #credits-explanation .pricing-table {
    margin: 20px auto;

    border-radius: 20px;
    border: 4px solid var(--border);
    padding: 4px;
}

#pricing #credits-explanation h2 {
    text-align: center;
    margin: 0 auto;
}

#pricing #credits-explanation .row-md {
    justify-content: center;
}

#pricing #credits-explanation .max-width-xl {
    max-width: inherit;
}

#pricing #credits-explanation .line-static {
    display: none;
}

.pricing-row {
    justify-items: center;
}

.pricing-cell-1st {
    font-size: 110%;
    align-items: end;
    justify-content: end;
    text-align: right;
    width: 100%;
}