.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-spinner .loading-indicator-sm {
    width: 3em;
}

.loading-spinner .loading-indicator-lg {
    margin-top: 4em;
}

.blog-entry {
    background-color: var(--neutral-800);
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: left;
    margin-bottom: 1.5em;
    padding: 1em;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.reverse > * {
    direction: ltr;
}

.blog-entry:hover {
    filter: brightness(1.2);
}

.blog-entry-content img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.blog-title {
    color: #4caf50;
    padding-left: 35%;
    text-align: start;
}

.blog-date {
    padding-left: 35%;
    font-size: 0.8em;
    color: #aaa;
}

.simple-grid {
    display: grid;
    grid-template-columns: [ col-start ] max-content [ col-start ] 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 1em;
    align-items: baseline;
}

.navbar {
    margin-top: 0 !important;
}

.navbar-login {
    display: flex;
    flex-direction: column;
}

.navbar-nav-menu {
    justify-self: center;
}

.section {
    padding-top: 2em;
}

.section.curve-bottom {
    padding-top: 5em;
}

.process-steps {
    padding-top: 2em;
}

.container {
    margin-top: 2em;
}

.blog-entries {
    display: grid;
    max-width: 850px;
    margin: 0 auto;
}

.blog-entries-container {
    width: 100%;
}

.header-team-pricing {
    height: 230px;
}

.items-justify-center {
    justify-content: center;
}

.bonus-tag {
    position: absolute;
    top: -30px;
    right: -40px;
    color: #FFF;
    background-color: #e35353;
    border-radius: 20px;
    transform: rotate(6deg);
    padding: 2px;
    padding-left: 8px;
    padding-right: 8px;
}

.centered {
    text-align: center;
}

/* Social Icons Styles */
.social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icons a {
    font-size: 2em; /* Larger icons */
    margin: 0 10px; /* Spacing between icons */
}

.page-content {
    min-height: 100vh;
}

.page-content > :first-child {
    padding-top: 5em;
}

.page-content > .overflow-hidden:first-child {
    padding-top: 0;
}

.navbar {
    position: fixed;
    z-index: 1001;
}

.navbar-list {
    display: flex;
}

.navbar-toggler {
    font-size: 30px;
}

.modal pre {
    /*noinspection CssInvalidPropertyValue*/
    text-wrap: wrap;
}

.testimonials .card {
    --bs-card-color: var(--text);
    --bs-card-title-color: var(--text);
    --bs-card-cap-color: var(--text);
    --bs-card-bg: var(--neutral-800);

    .card-footer .card-text {
        opacity: 50%;
    }
}

.table-of-contents .card {
    --bs-card-color: var(--text);
    --bs-card-title-color: var(--text);
    --bs-card-cap-color: var(--text);
    --bs-card-bg: var(--background);

    .card-body li div {
        color: var(--blue-400)
    }
}

.table-of-contents {
    width: 300px;
    background-color: var(--neutral-800);
    border-radius: 10px;
}

@media (max-width: 600px) {
    .table-of-contents {
        margin: 0 auto;
    }
}

@media (min-width: 600px) and (max-width: 1500px) {
    .table-of-contents {
        float: right;
    }
}

@media (min-width: 1500px) {
    .table-of-contents {
        position: absolute;
        left: -320px;
    }
}

.close-button {
    background-image: none;
    background-color: transparent;
    font-size: 1.5em;
    color: var(--text);
}

.close-button:hover {
    color: var(--neutral-50);
}

.download-button {
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 300px;
}

.accept-tos-page {
    .style-header {
        padding: 2em;
    }

    pre {
        max-height: 50vh;
    }
}

.modal-content {
    --bs-modal-bg: var(--neutral-700);
    --bs-modal-color: var(--text);
    --bs-modal-border-color: var(--neutral-500);
    --bs-modal-header-border-color: var(--neutral-600);
    --bs-modal-footer-border-color: var(--neutral-700);

    .modal-header {
        background-color: var(--neutral-600);
    }
}

.button-danger {
    background-color: var(--red-500)
}

.navbar-nav {
    margin-left: auto;
    margin-right: auto;
}

.navbar-collapse {
    justify-self: center;
}

.navbar-blur {
    z-index: 0;
    background-color: transparent;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.navbar > div > .navbar-blur {
    display: none;
}

.navbar:has(.show) > div > .navbar-blur {
    display: block;
}

.navbar > .navbar-blur {
    display: block;
}

.navbar:has(.show) > .navbar-blur {
    display: none;
}

.navbar {
    padding: 0;
}

.navbar > div {
    padding: 10px 0;
}

.full-page-gradient {
    min-height: 100vh;
}

.gradient__01 {
    border-radius: 1px;
    height: 100%;
    background-image: radial-gradient(circle farthest-corner at -25% -50%, var(--background) 36%, rgba(13, 17, 23, 0) 48%),
    radial-gradient(circle farthest-corner at -25% 150%, var(--background) 45%, rgba(13, 17, 23, 0) 89%),
    radial-gradient(circle farthest-corner at -25% -100%, rgba(13, 17, 23, 0) 55%, rgba(13, 17, 23, .28) 65%, rgba(13, 17, 23, 0) 68%),
    radial-gradient(circle farthest-corner at -33% -75%, #1f00cc 48%, rgba(131, 5, 49, 0) 56%),
    radial-gradient(circle farthest-side at 0% -50%, rgba(13, 17, 23, 0) 64%, rgba(1, 2, 36, .4) 69%, rgba(13, 17, 23, 0) 81%),
    radial-gradient(circle farthest-corner at 0% -50%, rgba(13, 17, 23, 0) 33%, #010c5f 51%, rgba(13, 17, 23, 0) 72%);
}

.gradient__05 {
    border-radius: 1px;
    height: 100%;
    background-image: radial-gradient(circle farthest-corner at 10% 10%, var(--background) 40%, rgba(13, 17, 23, 0) 60%),
    radial-gradient(circle farthest-corner at 10% 120%, var(--background) 50%, rgba(13, 17, 23, 0) 95%),
    radial-gradient(circle farthest-corner at 10% -80%, rgba(13, 17, 23, 0) 65%, rgba(13, 17, 23, .2) 75%, rgba(13, 17, 23, 0) 78%),
    radial-gradient(circle farthest-corner at 0% -50%, #1f00cc 58%, rgba(131, 5, 49, 0) 66%),
    radial-gradient(circle farthest-side at 20% 0%, rgba(13, 17, 23, 0) 74%, rgba(1, 2, 36, .3) 79%, rgba(13, 17, 23, 0) 91%),
    radial-gradient(circle farthest-corner at 20% 0%, rgba(13, 17, 23, 0) 43%, #010c5f 61%, rgba(13, 17, 23, 0) 82%);
}

.gradient__06 {
    border-radius: 1px;
    height: 100%;
    background-image: radial-gradient(circle farthest-corner at -25% -50%, var(--background) 36%, rgba(13, 17, 23, 0) 48%),
    radial-gradient(circle farthest-corner at -25% 150%, var(--background) 45%, rgba(13, 17, 23, 0) 89%),
    radial-gradient(circle farthest-corner at -25% -100%, rgba(13, 17, 23, 0) 55%, rgba(13, 23, 17, .28) 65%, rgba(13, 17, 23, 0) 68%),
    radial-gradient(circle farthest-corner at -33% -75%, #0c661e 48%, rgba(49, 131, 5, 0) 56%),
    radial-gradient(circle farthest-side at 0% -50%, rgba(13, 17, 23, 0) 64%, rgba(2, 36, 1, .4) 69%, rgba(13, 17, 23, 0) 81%),
    radial-gradient(circle farthest-corner at 0% -50%, rgba(13, 17, 23, 0) 33%, #1c5f01 51%, rgba(13, 17, 23, 0) 72%);
}

.gradient__07 {
    border-radius: 1px;
    height: 100%;
    background-image: radial-gradient(circle farthest-corner at -25% -50%, var(--background) 36%, rgba(13, 17, 23, 0.5) 48%),
    radial-gradient(circle farthest-corner at -25% 150%, var(--background) 45%, rgba(13, 17, 23, 0.5) 89%),
    radial-gradient(circle farthest-corner at -25% -100%, rgba(13, 17, 23, 0.5) 55%, rgba(13, 23, 17, .68) 65%, rgba(13, 17, 23, 0.5) 68%),
    radial-gradient(circle farthest-corner at -33% -75%, #0c661e 48%, rgba(49, 131, 5, 0.5) 56%),
    radial-gradient(circle farthest-side at 0% -50%, rgba(13, 17, 23, 0.5) 64%, rgba(2, 36, 1, .8) 69%, rgba(13, 17, 23, 0.5) 81%),
    radial-gradient(circle farthest-corner at 0% -50%, rgba(13, 17, 23, 0.5) 33%, #1c5f01 51%, rgba(13, 17, 23, 0.5) 72%);
}

.gradient__07_smooth {
    border-radius: 1px;
    height: 100%;
    background-image: radial-gradient(circle farthest-corner at -25% -50%, var(--background) 36%, rgba(13, 17, 23, 0.6) 60%),
    radial-gradient(circle farthest-corner at -25% 150%, var(--background) 45%, rgba(13, 17, 23, 0.6) 95%),
    radial-gradient(circle farthest-corner at -25% -100%, rgba(13, 17, 23, 0.6) 55%, rgba(13, 23, 17, .78) 75%, rgba(13, 17, 23, 0.6) 78%),
    radial-gradient(circle farthest-corner at -33% -75%, #0c661e 48%, rgba(49, 131, 5, 0.6) 66%),
    radial-gradient(circle farthest-side at 0% -50%, rgba(13, 17, 23, 0.6) 64%, rgba(2, 36, 1, .9) 79%, rgba(13, 17, 23, 0.6) 91%),
    radial-gradient(circle farthest-corner at 0% -50%, rgba(13, 17, 23, 0.6) 33%, #1c5f01 61%, rgba(13, 17, 23, 0.6) 82%);
}

.toc-level-2 {
    margin-left: 1em;
}

.toc-level-3 {
    margin-left: 2em;
}

.toc-level-4 {
    margin-left: 3em;
}

.toc-level-5 {
    margin-left: 4em;
}

.toc-level-6 {
    margin-left: 5em;
}

.cursor-pointer {
    cursor: pointer;
}

.timeline-image-box {
    filter: none;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.timeline-chapter-item {
    .title-sm {
        font-size: 32px;
        line-height: 1;
        margin-bottom: 5px
    }
}

.post-section {
    overflow: initial;
}

.login-buttons button.button-animated {
    margin-top: 1em;
    margin-bottom: 1.5em;
}

.testimonial-outer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.error-message {
    color: var(--red-500);
}

.oauth-header {
    margin: 10px;
}

.oauth-body {
    margin: 10px;
}

.oauth-buttons {
    display: flex;
    justify-content: center;

    button {
        width: 180px;
    }
}

.safari-warning {
    color: var(--yellow-300);
}

.tos-privacy-links {
    margin-top: 1em; /* Adjust the value as needed */
}

@media (max-width: 991px) {
    .navbar-list {
        align-items: baseline;
    }

    .navbar-login {
        flex-grow: 1;
        flex-flow: row;
    }

    .toggle-show-mobile {
        display: block;
    }

    .toggle-hide-mobile {
        display: none;
    }
}

@media (min-width: 992px) {
    .toggle-show-mobile {
        display: none;
    }

    .toggle-hide-mobile {
        display: block;
    }
}

@media (min-width: 1500px) {
    .react-multi-carousel-list {
        justify-content: center;
    }
}

.blog-entry-see-also {
    margin-top: 50px;

    > h3 {
        text-align: center;
        margin-bottom: 10px;
    }
}

.w-embed-youtubevideo {
    background: none;
}

.main-youtube-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}